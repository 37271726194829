import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from 'react-spring/renderprops-addons.cjs'
import { useTranslation } from 'react-i18next';
import i18n from "../../i18n";

// Components
import Layout from '../components/Layout'
import ProjectCard from '../components/ProjectCard'

// Elements
import Inner from '../elements/Inner'
import { Title, BigTitle, Subtitle } from '../elements/Titles'

// Views
import Hero from '../views/Hero'
import Projects from '../views/Projects'
import About from '../views/About'
import Contact from '../views/Contact'
import Work from '../views/Work'
import Education from '../views/Education'

import '../views/Work.css';

// Images
import me from '../images/me.webp';
import en from '../images/uk.png';
import de from '../images/de.png';
import es from '../images/es.png';
import fr from '../images/fr.png';
import it from '../images/it.png';
import sq from '../images/sq.png';

const ProjectsWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`

const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8`};
`

const Avatar = styled.img`
  ${tw` w-32 xl:w-48 shadow-lg h-auto`};
`

const AboutSub = styled.span`
  ${tw`text-white pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
`

const AboutDesc = styled.p`
  ${tw`text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
`

const ContactText = styled.p`
  ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
`

const Footer = styled.footer`
  ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
`

const Index = () => {

  const { t } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const languageOptions = [
    { value: "en", label: "English", flag: en },
    { value: "sq", label: "Shqip", flag: sq },
    { value: "it", label: "Italiano", flag: it },
    { value: "fr", label: "Français", flag: fr },
    { value: "es", label: "Español", flag: es },
    { value: "de", label: "Deutsch", flag: de }
  ];

  return (
  <>
    <Layout />
    <Parallax pages={10}>

    <center><div style={{marginTop: '3%'}}>{languageOptions.map((i) => <img src={i.flag} alt='' height="30px" style={{marginRight: "5px"}} onClick={(e) => changeLanguage(i.value)}/>)}</div></center>
      
    <Hero offset={0}>
        <BigTitle>
        {t('greet')} <br /> {t('name')}
        </BigTitle>
        <Subtitle>{t('subtitle')}</Subtitle>
      </Hero>

      <Projects offset={2}>
        <Title>{t('projects')}</Title>
        <ProjectsWrapper>
          <ProjectCard
            title="HitWeb"
            link="https://hitweb.it"
            bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)"
          >
          </ProjectCard>
          <ProjectCard
            title="Group Analysis Albania"
            link="https://groupanalysisalbania.org/"
            bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
          >
          </ProjectCard>
          <ProjectCard
            title="FAAS"
            link="http://faasarchitects.al/"
            bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
          >
          </ProjectCard>
          <ProjectCard
            title="Saga Recruiting"
            link="http://sagarecruiting.al/"
            bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)"
          >
          </ProjectCard>
          <ProjectCard
            title="Sapientify"
            link="https://sapientify.com.al/"
            bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
          >
          </ProjectCard>
          <ProjectCard
            title="AlbaSolutions"
            link="http://albasolutions.fr/"
            bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
          >
          </ProjectCard>
          <ProjectCard
            title="Stema"
            link="https://stema.al/"
            bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
          >
          </ProjectCard>
          <ProjectCard
            title="Vaanmed"
            link="https://vaanmed.com/"
            bg="linear-gradient(to right, #D580FF 0%, #E6D32B 100%)"
          >
          </ProjectCard>
          <ProjectCard
            title="DEKA Laser"
            link="https://dekalaser.al/"
            bg="linear-gradient(to right, #D5000F 0%, #E6E00B 100%)"
          >
          </ProjectCard>
          <ProjectCard
            title="GSA"
            link="http://group-gsa.com/"
            bg="linear-gradient(to right, #A0102A 0%, #B3E01B 100%)"
          >
          </ProjectCard>
          <ProjectCard
            title="Xhelips"
            link="https://xhelips.al/"
            bg="linear-gradient(to right, #E010C0 0%, #D3D01D 100%)"
          >
          </ProjectCard>
          <ProjectCard
            title="UBA"
            link="https://ebanking.uba.com.al/"
            bg="linear-gradient(to right, #C010B0 0%, #E3D01C 100%)"
          >
          </ProjectCard>
        </ProjectsWrapper>
      </Projects>

      <About offset={4}>
        <Title>{t('resume')}</Title>
        <AboutHero>
          <Avatar src={me} alt="Daniel Kapexhiu" />
          <AboutSub>
            {t('subresume')} 
          </AboutSub>
        </AboutHero>
        <AboutDesc>
            {t('descresume')}
        </AboutDesc>
      </About>

      <Work offset={6}>
        <Title>{t('work')}</Title>
<div class="history flex flex-col relative pt-6 pb-6">
    <div class="history-entry relative w-1/2 py-4">
        <span class="dot-bg w-14 h-14 transform absolute bg-line inline-flex rounded-full items-center justify-center"><span class="dot w-5 h-5 bg-back-light rounded-full"></span></span>
        <h4 class="subpixel-antialiased">{t('uba1')}</h4>
        <h5 class="text-sm font-normal mb-1">{t('uba2')}</h5>
        <span class="text-sm font-medium opacity-50">{t('uba3')}</span>
    </div>
    <div class="history-entry relative w-1/2 py-4">
        <span class="dot-bg w-14 h-14 transform absolute bg-line inline-flex rounded-full items-center justify-center"><span class="dot w-5 h-5 bg-back-light rounded-full"></span></span>
        <h4 class="subpixel-antialiased">{t('kinetx1')}</h4>
        <h5 class="text-sm font-normal mb-1">{t('kinetx2')}</h5>
        <span class="text-sm font-medium opacity-50">{t('kinetx3')}</span>
    </div>
    <div class="history-entry relative w-1/2 py-4">
        <span class="dot-bg w-14 h-14 transform absolute bg-line inline-flex rounded-full items-center justify-center"><span class="dot w-5 h-5 bg-back-light rounded-full"></span></span>
        <h4 class="subpixel-antialiased">{t('stema1')}</h4>
        <h5 class="text-sm font-normal mb-1">{t('stema2')}</h5>
        <span class="text-sm font-medium opacity-50">{t('stema3')}</span>
    </div>
    <div class="history-entry relative w-1/2 py-4">
        <span class="dot-bg w-14 h-14 transform absolute bg-line inline-flex rounded-full items-center justify-center"><span class="dot w-5 h-5 bg-back-light rounded-full"></span></span>
        <h4 class="subpixel-antialiased">{t('soft1')}</h4>
        <h5 class="text-sm font-normal mb-1">{t('soft2')}</h5>
        <span class="text-sm font-medium opacity-50">{t('soft3')}</span>
    </div>
    <div class="history-entry relative w-1/2 py-4">
        <span class="dot-bg w-14 h-14 transform absolute bg-line inline-flex rounded-full items-center justify-center"><span class="dot w-5 h-5 bg-back-light rounded-full"></span></span>
        <h4 class="subpixel-antialiased">{t('uni1')}</h4>
        <h5 class="text-sm font-normal mb-1">{t('uni2')}</h5>
        <span class="text-sm font-medium opacity-50">{t('uni3')}</span>
    </div>
    <div class="history-entry relative w-1/2 py-4">
        <span class="dot-bg w-14 h-14 transform absolute bg-line inline-flex rounded-full items-center justify-center"><span class="dot w-5 h-5 bg-back-light rounded-full"></span></span>
        <h4 class="subpixel-antialiased">{t('hitweb1')}</h4>
        <h5 class="text-sm font-normal mb-1">{t('hitweb2')}</h5>
        <span class="text-sm font-medium opacity-50">{t('hitweb3')}</span>
    </div>
    <div class="history-entry relative w-1/2 py-4">
        <span class="dot-bg w-14 h-14 transform absolute bg-line inline-flex rounded-full items-center justify-center"><span class="dot w-5 h-5 bg-back-light rounded-full"></span></span>
        <h4 class="subpixel-antialiased">{t('myfast1')}</h4>
        <h5 class="text-sm font-normal mb-1">{t('myfast2')}</h5>
        <span class="text-sm font-medium opacity-50">{t('myfast3')}</span>
    </div>
    <div class="history-entry relative w-1/2 py-4">
        <span class="dot-bg w-14 h-14 transform absolute bg-line inline-flex rounded-full items-center justify-center"><span class="dot w-5 h-5 bg-back-light rounded-full"></span></span>
        <h4 class="subpixel-antialiased">{t('genio1')}</h4>
        <h5 class="text-sm font-normal mb-1">{t('genio2')}</h5>
        <span class="text-sm font-medium opacity-50">{t('genio3')}</span>
    </div>
</div>
      </Work>

      <Education offset={8}>
        <Title>{t('edu')}</Title>
<div class="history flex flex-col relative pt-6 pb-6">
    <div class="history-entry relative w-1/2 py-4">
        <span class="dot-bg w-14 h-14 transform absolute bg-line inline-flex rounded-full items-center justify-center"><span class="dot w-5 h-5 bg-back-light rounded-full"></span></span>
        <h4 class="subpixel-antialiased">{t('udacity1')}</h4>
        <h5 class="text-sm font-normal mb-1">{t('udacity2')}</h5>
        <span class="text-sm font-medium opacity-50">{t('udacity3')}</span>
    </div>
    <div class="history-entry relative w-1/2 py-4">
        <span class="dot-bg w-14 h-14 transform absolute bg-line inline-flex rounded-full items-center justify-center"><span class="dot w-5 h-5 bg-back-light rounded-full"></span></span>
        <h4 class="subpixel-antialiased">{t('oc1')}</h4>
        <h5 class="text-sm font-normal mb-1">{t('oc2')}</h5>
        <span class="text-sm font-medium opacity-50">{t('oc3')}</span>
    </div>
    <div class="history-entry relative w-1/2 py-4">
        <span class="dot-bg w-14 h-14 transform absolute bg-line inline-flex rounded-full items-center justify-center"><span class="dot w-5 h-5 bg-back-light rounded-full"></span></span>
        <h4 class="subpixel-antialiased">{t('unipi1')}</h4>
        <h5 class="text-sm font-normal mb-1">{t('unipi2')}</h5>
        <span class="text-sm font-medium opacity-50">{t('unipi3')}</span>
    </div>
    <div class="history-entry relative w-1/2 py-4">
        <span class="dot-bg w-14 h-14 transform absolute bg-line inline-flex rounded-full items-center justify-center"><span class="dot w-5 h-5 bg-back-light rounded-full"></span></span>
        <h4 class="subpixel-antialiased">{t('trainers1')}</h4>
        <h5 class="text-sm font-normal mb-1">{t('trainers2')}</h5>
        <span class="text-sm font-medium opacity-50">{t('trainers3')}</span>
    </div>
</div>
      </Education>

      <Contact offset={9}>
        <Inner>
          <Title>{t('contact')}</Title>
          <ContactText>
            <a href="mailto:daniel.kapexhiu@gmail.com">Email</a> ,{' '}
            <a href="https://github.com/dkapexhiu/">GitHub</a> ,{' '}
            <a href="https://linkedin.com/in/danielkapexhiu">LinkedIn</a>
          </ContactText>
        </Inner>
        <Footer>
          &copy; 2024 Daniel Kapexhiu
        </Footer>
      </Contact>

    </Parallax>
  </>
)
};

export default Index
