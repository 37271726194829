// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        "greet": "Hello,",
        "name": "I'm Daniel Kapexhiu.",
        "subtitle": "I'm creating nice web experiences for the next generation of consumer-facing companies.",
        "projects": "Projects",
        "resume": "Resume",
        "subresume": "I am a Software Developer and Mobile Web Specialist from OpenClassrooms and Udacity.",
        "descresume": "I have followed and done the course Crescere in Digitale for supporting the process of digitalization of italian factories. I have been certified as a Digital Marketer from Ecellenze in Digitale course. I have completed the Google Partners program and certified in Google Adwords. I have developed in HTML5, CSS3, Javascript, SQL, PHP, React.js, jQuery, Bootstrap, KnockoutJS, React Native, Vue.js, Laravel, Jigsaw, Node.js, .NET Core, C#, Java, C/C++, Python.",
        "uba1": "UBA",
        "uba2": "Software Developer",
        "uba3": "July 2021 - Present",
        "kinetx1": "Kinetx",
        "kinetx2": "React.js Developer",
        "kinetx3": "March 2021 - April 2021",
        "stema1": "Stema Digital",
        "stema2": "Mobile Web Specialist",
        "stema3": "December 2019 - June 2021",
        "soft1": "Soft & Solution",
        "soft2": "Webmaster",
        "soft3": "June 2019 - December 2019",
        "uni1": "UniAdmissions",
        "uni2": "Web Developer",
        "uni3": "June 2019 - June 2019",
        "work": "Work",
        "hitweb1": "HitWeb",
        "hitweb2": "Web Developer",
        "hitweb3": "June 2018 - June 2019",
        "myfast1": "MyFastWay",
        "myfast2": "Digital Marketing Technician",
        "myfast3": "February 2017 - September 2017",
        "genio1": "Your Trainers Group",
        "genio2": "Collaborator",
        "genio3": "June 2016 - July 2016",
        "edu": "Education",
        "udacity1": "Udacity",
        "udacity2": "Mobile Web Specialist",
        "udacity3": "2017 - 2018",
        "oc1": "OpenClassrooms",
        "oc2": "Web Developer",
        "oc3": "2017-2018",
        "unipi1": "University of Pisa",
        "unipi2": "Computer & Biomedical Engineering",
        "unipi3": "2011-2017",
        "trainers1": "Your Trainers Group",
        "trainers2": "Academy of Excellence",
        "trainers3": "2016 - 2016",
        "contact": "Get in touch"
      },
    },
    sq: {
      translation: {
        "greet": "Ckemi,",
        "name": "Une jam Daniel Kapexhiu.",
        "subtitle": "Unë jam duke krijuar përvoja të këndshme në internet për gjeneratën e ardhshme të kompanive që përballen me konsumatorët.",
        "projects": "Projektet",
        "resume": "CV",
        "subresume": "Jam nje Software Developer dhe Mobile Web Specialist nga OpenClassrooms dhe Udacity.",
        "descresume": "Kam ndjekur dhe kryer kursin Crescere in Digitale për mbështetjen e procesit të dixhitalizimit të fabrikave italiane. Jam certifikuar si Marketer Dixhital nga kursi Ecellenze në Digitale. Kam përfunduar programin Google Partners dhe jam certifikuar në Google Adwords. Unë kam zhvilluar në HTML5, CSS3, Javascript, SQL, PHP, React.js, jQuery, Bootstrap, KnockoutJS, React Native, Vue.js, Laravel, Jigsaw, Node.js, .NET Core, C#, Java, C/C++, Python.",
        "uba1": "UBA",
        "uba2": "Software Developer",
        "uba3": "Korrik 2021 - Tani",
        "kinetx1": "Kinetx",
        "kinetx2": "React.js Developer",
        "kinetx3": "Mars 2021 - Prill 2021",
        "stema1": "Stema Digital",
        "stema2": "Mobile Web Specialist",
        "stema3": "Dhjetor 2019 - Qershor 2021",
        "soft1": "Soft & Solution",
        "soft2": "Webmaster",
        "soft3": "Qershor 2019 - Dhjetor 2019",
        "uni1": "UniAdmissions",
        "uni2": "Web Developer",
        "uni3": "Qershor 2019 - Qershor 2019",
        "work": "Puna",
        "hitweb1": "HitWeb",
        "hitweb2": "Web Developer",
        "hitweb3": "Qershor 2018 - Qershor 2019",
        "myfast1": "MyFastWay",
        "myfast2": "Digital Marketing Technician",
        "myfast3": "Shkurt 2017 - Shtator 2017",
        "genio1": "Your Trainers Group",
        "genio2": "Collaborator",
        "genio3": "Qershor 2016 - Korrik 2016",
        "edu": "Edukimi",
        "udacity1": "Udacity",
        "udacity2": "Mobile Web Specialist",
        "udacity3": "2017 - 2018",
        "oc1": "OpenClassrooms",
        "oc2": "Web Developer",
        "oc3": "2017-2018",
        "unipi1": "University of Pisa",
        "unipi2": "Inxhinieri Informatike & Biomjekesore",
        "unipi3": "2011-2017",
        "trainers1": "Your Trainers Group",
        "trainers2": "Academy of Excellence",
        "trainers3": "2016 - 2016",
        "contact": "Kontakt"
      },
    },
    it: {
      translation: {
          "greet": "Ciao,",
          "name": "Sono Daniel Kapexhiu.",
          "subtitle": "Sto creando belle esperienze web per la prossima generazione di aziende rivolte ai consumatori.",
          "projects": "Progetti",
          "resume": "Curriculum",
          "subresume": "Sono uno sviluppatore software e specialista in web mobile da OpenClassrooms e Udacity.",
          "descresume": "Ho seguito e completato il corso Crescere in Digitale per supportare il processo di digitalizzazione delle fabbriche italiane. Sono stato certificato come Digital Marketer dal corso Ecellenze in Digitale. Ho completato il programma Google Partners e sono certificato in Google Adwords. Ho sviluppato in HTML5, CSS3, Javascript, SQL, PHP, React.js, jQuery, Bootstrap, KnockoutJS, React Native, Vue.js, Laravel, Jigsaw, Node.js, .NET Core, C#, Java, C/C++, Python.",
          "uba1": "UBA",
          "uba2": "Sviluppatore software",
          "uba3": "Luglio 2021 - Presente",
          "kinetx1": "Kinetx",
          "kinetx2": "Sviluppatore React.js",
          "kinetx3": "Marzo 2021 - Aprile 2021",
          "stema1": "Stema Digital",
          "stema2": "Specialista in web mobile",
          "stema3": "Dicembre 2019 - Giugno 2021",
          "soft1": "Soft & Solution",
          "soft2": "Webmaster",
          "soft3": "Giugno 2019 - Dicembre 2019",
          "uni1": "UniAdmissions",
          "uni2": "Sviluppatore web",
          "uni3": "Giugno 2019 - Giugno 2019",
          "work": "Lavoro",
          "hitweb1": "HitWeb",
          "hitweb2": "Sviluppatore web",
          "hitweb3": "Giugno 2018 - Giugno 2019",
          "myfast1": "MyFastWay",
          "myfast2": "Tecnico di marketing digitale",
          "myfast3": "Febbraio 2017 - Settembre 2017",
          "genio1": "Il tuo gruppo di formatori",
          "genio2": "Collaboratore",
          "genio3": "Giugno 2016 - Luglio 2016",
          "edu": "Educazione",
          "udacity1": "Udacity",
          "udacity2": "Specialista in Sviluppo Web Mobile",
          "udacity3": "2017 - 2018",
          "oc1": "OpenClassrooms",
          "oc2": "Sviluppatore Web",
          "oc3": "2017-2018",
          "unipi1": "Università di Pisa",
          "unipi2": "Ingegneria Informatica e Biomedica",
          "unipi3": "2011-2017",
          "trainers1": "Il tuo gruppo di formatori",
          "trainers2": "Accademia dell'Eccellenza",
          "trainers3": "2016 - 2016",
          "contact": "Contattaci"
        },
    },
    fr: {
      translation: {
        "greet": "Bonjour,",
        "name": "Je suis Daniel Kapexhiu.",
        "subtitle": "Je crée de belles expériences web pour la prochaine génération d'entreprises orientées vers les consommateurs.",
        "projects": "Projets",
        "resume": "CV",
        "subresume": "Je suis développeur de logiciels et spécialiste du web mobile, formé chez OpenClassrooms et Udacity.",
        "descresume": "J'ai suivi et terminé le cours Crescere in Digitale pour soutenir le processus de numérisation des usines italiennes. J'ai été certifié en tant que spécialiste du marketing numérique grâce au cours Ecellenze in Digitale. J'ai terminé le programme Google Partners et obtenu la certification Google Adwords. J'ai développé en HTML5, CSS3, Javascript, SQL, PHP, React.js, jQuery, Bootstrap, KnockoutJS, React Native, Vue.js, Laravel, Jigsaw, Node.js, .NET Core, C#, Java, C/C++, Python.",
        "uba1": "UBA",
        "uba2": "Développeur de logiciels",
        "uba3": "Juillet 2021 - Présent",
        "kinetx1": "Kinetx",
        "kinetx2": "Développeur React.js",
        "kinetx3": "Mars 2021 - Avril 2021",
        "stema1": "Stema Digital",
        "stema2": "Spécialiste du web mobile",
        "stema3": "Décembre 2019 - Juin 2021",
        "soft1": "Soft & Solution",
        "soft2": "Webmaster",
        "soft3": "Juin 2019 - Décembre 2019",
        "uni1": "UniAdmissions",
        "uni2": "Développeur web",
        "uni3": "Juin 2019 - Juin 2019",
        "work": "Travail",
        "hitweb1": "HitWeb",
        "hitweb2": "Développeur web",
        "hitweb3": "Juin 2018 - Juin 2019",
        "myfast1": "MyFastWay",
        "myfast2": "Technicien en marketing numérique",
        "myfast3": "Février 2017 - Septembre 2017",
        "genio1": "Votre groupe de formateurs",
        "genio2": "Collaborateur",
        "genio3": "Juin 2016 - Juillet 2016",
        "edu": "Éducation",
        "udacity1": "Udacity",
        "udacity2": "Spécialiste du Web Mobile",
        "udacity3": "2017 - 2018",
        "oc1": "OpenClassrooms",
        "oc2": "Développeur Web",
        "oc3": "2017-2018",
        "unipi1": "Université de Pise",
        "unipi2": "Ingénierie informatique et biomédicale",
        "unipi3": "2011-2017",
        "trainers1": "Votre groupe de formateurs",
        "trainers2": "Académie d'Excellence",
        "trainers3": "2016 - 2016",
        "contact": "Contactez-nous"
      },
    },
    es: {
      translation: {
        "greet": "Hola,",
        "name": "Soy Daniel Kapexhiu.",
        "subtitle": "Estoy creando experiencias web agradables para la próxima generación de empresas orientadas al consumidor.",
        "projects": "Proyectos",
        "resume": "Currículum",
        "subresume": "Soy un Desarrollador de Software y Especialista en Web Móvil de OpenClassrooms y Udacity.",
        "descresume": "He seguido y completado el curso Crescere in Digitale para apoyar el proceso de digitalización de fábricas italianas. He sido certificado como Digital Marketer en el curso Ecellenze in Digitale. He completado el programa de Google Partners y estoy certificado en Google Adwords. He desarrollado en HTML5, CSS3, Javascript, SQL, PHP, React.js, jQuery, Bootstrap, KnockoutJS, React Native, Vue.js, Laravel, Jigsaw, Node.js, .NET Core, C#, Java, C/C++, Python.",
        "uba1": "UBA",
        "uba2": "Desarrollador de Software",
        "uba3": "Julio 2021 - Presente",
        "kinetx1": "Kinetx",
        "kinetx2": "Desarrollador de React.js",
        "kinetx3": "Marzo 2021 - Abril 2021",
        "stema1": "Stema Digital",
        "stema2": "Especialista en Web Móvil",
        "stema3": "Diciembre 2019 - Junio 2021",
        "soft1": "Soft & Solution",
        "soft2": "Webmaster",
        "soft3": "Junio 2019 - Diciembre 2019",
        "uni1": "UniAdmissions",
        "uni2": "Desarrollador Web",
        "uni3": "Junio 2019 - Junio 2019",
        "work": "Trabajo",
        "hitweb1": "HitWeb",
        "hitweb2": "Desarrollador Web",
        "hitweb3": "Junio 2018 - Junio 2019",
        "myfast1": "MyFastWay",
        "myfast2": "Técnico de Marketing Digital",
        "myfast3": "Febrero 2017 - Septiembre 2017",
        "genio1": "Tu Grupo de Entrenadores",
        "genio2": "Colaborador",
        "genio3": "Junio 2016 - Julio 2016",
        "edu": "Educación",
        "udacity1": "Udacity",
        "udacity2": "Especialista en Desarrollo Web Móvil",
        "udacity3": "2017 - 2018",
        "oc1": "OpenClassrooms",
        "oc2": "Desarrollador Web",
        "oc3": "2017-2018",
        "unipi1": "Universidad de Pisa",
        "unipi2": "Ingeniería Informática y Biomédica",
        "unipi3": "2011-2017",
        "trainers1": "Tu Grupo de Entrenadores",
        "trainers2": "Academia de Excelencia",
        "trainers3": "2016 - 2016",
        "contact": "Ponte en contacto"  
      },
    },
    de: {
      translation: {
        "greet": "Hallo,",
        "name": "Ich bin Daniel Kapexhiu.",
        "subtitle": "Ich erstelle schöne Web-Erlebnisse für die nächste Generation von kundenorientierten Unternehmen.",
        "projects": "Projekte",
        "resume": "Lebenslauf",
        "subresume": "Ich bin ein Softwareentwickler und Mobile Web-Spezialist von OpenClassrooms und Udacity.",
        "descresume": "Ich habe den Kurs Crescere in Digitale absolviert, um den Digitalisierungsprozess italienischer Fabriken zu unterstützen. Ich wurde als Digital Marketer im Kurs Ecellenze in Digitale zertifiziert. Ich habe das Google Partners-Programm abgeschlossen und bin in Google Adwords zertifiziert. Ich habe in HTML5, CSS3, Javascript, SQL, PHP, React.js, jQuery, Bootstrap, KnockoutJS, React Native, Vue.js, Laravel, Jigsaw, Node.js, .NET Core, C#, Java, C/C++, Python entwickelt.",
        "uba1": "UBA",
        "uba2": "Softwareentwickler",
        "uba3": "Juli 2021 - heute",
        "kinetx1": "Kinetx",
        "kinetx2": "React.js-Entwickler",
        "kinetx3": "März 2021 - April 2021",
        "stema1": "Stema Digital",
        "stema2": "Mobile Web-Spezialist",
        "stema3": "Dezember 2019 - Juni 2021",
        "soft1": "Soft & Solution",
        "soft2": "Webmaster",
        "soft3": "Juni 2019 - Dezember 2019",
        "uni1": "UniAdmissions",
        "uni2": "Webentwickler",
        "uni3": "Juni 2019 - Juni 2019",
        "work": "Arbeit",
        "hitweb1": "HitWeb",
        "hitweb2": "Webentwickler",
        "hitweb3": "Juni 2018 - Juni 2019",
        "myfast1": "MyFastWay",
        "myfast2": "Digital Marketing Technician",
        "myfast3": "Februar 2017 - September 2017",
        "genio1": "Deine Trainergruppe",
        "genio2": "Mitarbeiter",
        "genio3": "Juni 2016 - Juli 2016",
        "edu": "Bildung",
        "udacity1": "Udacity",
        "udacity2": "Mobile Web Spezialist",
        "udacity3": "2017 - 2018",
        "oc1": "OpenClassrooms",
        "oc2": "Webentwickler",
        "oc3": "2017-2018",
        "unipi1": "Universität Pisa",
        "unipi2": "Informatik & Biomedizinische Technik",
        "unipi3": "2011-2017",
        "trainers1": "Deine Trainergruppe",
        "trainers2": "Akademie der Exzellenz",
        "trainers3": "2016 - 2016",
        "contact": "Kontakt aufnehmen"
      },
    },
  },
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
